var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{attrs:{"bordered":false}},[_c('page-header-wrapper',{attrs:{"Style":{ padding: '3px 10px'},"title":false}}),_c('div',{staticClass:"account-settings-info-view"},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 4 },"wrapper-col":{ span: 18 },"layout":"vertical"},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"span":18}},[_c('a-form-item',{attrs:{"label":"系统名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'siteName'
              ]),expression:"[\n                'siteName'\n              ]"}],attrs:{"placeholder":"请输入站点名称"}})],1),_c('a-form-item',{attrs:{"required":false,"label":"客服电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'serviceTel'
              ]),expression:"[\n                'serviceTel'\n              ]"}],attrs:{"placeholder":"请输入客服电话"}})],1),_c('a-form-item',{attrs:{"required":false,"label":"备案号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'recordNumber'
              ]),expression:"[\n                'recordNumber'\n              ]"}],attrs:{"placeholder":"请输入备案号"}})],1),_c('a-form-item',{attrs:{"required":false,"label":"版权信息"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'copyright'
              ]),expression:"[\n                'copyright'\n              ]"}],attrs:{"placeholder":"请输入版权信息"}})],1),_c('a-form-item',[_c('a-button',{attrs:{"html-type":"submit","type":"primary"}},[_vm._v("保存")]),_c('a-button',{staticStyle:{"margin-left":"8px"},on:{"click":_vm.handleReset}},[_vm._v("重置")])],1)],1),_c('a-col',{style:({ minHeight: '180px' }),attrs:{"span":6}},[_c('a-form-item',[_c('AvatarUpload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'logo'
              ]),expression:"[\n                'logo'\n              ]"}]})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }