<template>
  <a-card :bordered="false">
    <page-header-wrapper :Style="{ padding: '3px 10px'}" :title="false">  </page-header-wrapper>
    <div class="account-settings-info-view">
      <a-form
        :form="form"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 18 }"
        layout="vertical"
        @submit="handleSubmit">
        <a-row>
          <a-col :span="18">

            <a-form-item
              label="系统名称"
            >
              <a-input
                v-decorator="[
                  'siteName'
                ]"
                placeholder="请输入站点名称"/>
            </a-form-item>
            <a-form-item
              :required="false"
              label="客服电话"
            >
              <a-input
                v-decorator="[
                  'serviceTel'
                ]"

                placeholder="请输入客服电话"
              />
            </a-form-item>
            <a-form-item
              :required="false"
              label="备案号"
            >
              <a-input
                v-decorator="[
                  'recordNumber'
                ]"

                placeholder="请输入备案号"
              />
            </a-form-item>
            <a-form-item
              :required="false"
              label="版权信息"
            >
              <a-input
                v-decorator="[
                  'copyright'
                ]"
                placeholder="请输入版权信息"
              />
            </a-form-item>
            <a-form-item>
              <a-button html-type="submit" type="primary">保存</a-button>
              <a-button style="margin-left: 8px" @click="handleReset">重置</a-button>
            </a-form-item>

          </a-col>
          <a-col :span="6" :style="{ minHeight: '180px' }">
            <a-form-item >
              <AvatarUpload
                v-decorator="[
                  'logo'
                ]"/>
            </a-form-item>
          </a-col>

        </a-row>
      </a-form>
    </div>
  </a-card>
</template>

<script>
import AvatarUpload from '@/components/upload/index'
import { getSetting, save } from '@/api/setting'
import pick from 'lodash.pick'

export default {
  components: {
    AvatarUpload
  },
  data () {
    return {
      form: this.$form.createForm(this),
      logo: 0
    }
  },
  mounted () {
    this.handleSettingInfo()
  },
  methods: {
    handleSettingInfo () {
      this.$nextTick(function () {
        const fields = ['siteName', 'serviceTel', 'recordNumber', 'copyright', 'logo']

        getSetting().then(res => {
            this.form.setFieldsValue(pick(res.result, fields))
          }
        )
      })
    },
    setavatar (url) {
      this.option.img = url
    },
    handleSubmit (e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          const self = this
          // 修改 e.g.
          save(values)
            .then(res => {
              if (res.code === 1) {
                self.$message.info(res.message)
              } else {
                self.$message.error(res.message)
              }
            })
        }
      })
    },
    handleReset () {
      this.form.resetFields()
    }
  }
}
</script>
